<template>
  <div class="lobby-tabs">
    <router-link :to="item.link" aria-current="page" :class="['tab',active==index?'active':'']" v-for="item,index in tabsList" :key="index">
      <span class="tab__icon">
        <img :src="item.icon" :alt="$t(item.name)"/>
      </span>
      <span class="tab__title">{{ $t(item.name) }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  props:{
    active:{
      default:0
    }
  },
  data(){
    return {
      tabsList:[
        {name:"我的账户",icon:require('@/assets/images/icon-my-account.79f1d6e.png'),link:"/account"},
        {name:"交易",icon:require('@/assets/images/icon-transactions.9e4cc46.png'),link:"/transactions"},
        {name:"游戏历史",icon:require('@/assets/images/icon-game-history.c57a6ff.png'),link:"/game-history"},
        {name:"账户设置",icon:require('@/assets/images/setting.png'),link:"/setting"},
      ]
    }
  }
};
</script>

<style scoped lang="less">
.lobby-tabs {
  background: #00241D;
  border-radius: 6px;
  padding: 8px;
  width: max-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: auto;
}

.tab {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  color: #AED9CC;
  text-decoration: none;
  background-color: transparent;
  border-radius: 6px;
  padding: 11px 25px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  flex: 1;
  justify-content: center;
  max-width: 200px;
  @media (min-width:768px) {
    display: flex;
    align-items: center;
  }
  @media (max-width:768px) {
    padding: 8px 15px;
    min-width: 0;
    font-size: 11px;
    overflow: hidden;
  }
}



.tab:hover {
  color: #fff;
}

.tab__icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  position: relative;
  @media (max-width:768px) {
    width: 35px;
    height: 35px;    
  }
  @media (min-width:769px) {
    margin-right: 10px;
  }
}

.tab__icon img {
  display: block;
  width: auto;
  height: 100%;
}

.tab__title {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tab__badge {
  color: #D7FFC4;
  margin-left: 8px;
  font-weight: 700;
  font-size: 10px;
  padding: 1px 6px;
  background: #fff;
  border-radius: 4px;
}

.tab.active {
  background-color: #FFD700;
  color: #917800;
}

.tab-item {
  display: flex;
  align-items: center;
}

.tab-item i {
  height: 1.5em;
  width: 1.5em;
}

.tab-item img {
  margin-right: 0.5em;
  width: 70%;
  height: 70%;
}
</style>
