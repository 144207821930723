import { render, staticRenderFns } from "./twoFaAct.vue?vue&type=template&id=aa321878&scoped=true&"
import script from "./twoFaAct.vue?vue&type=script&lang=js&"
export * from "./twoFaAct.vue?vue&type=script&lang=js&"
import style0 from "./twoFaAct.vue?vue&type=style&index=0&id=aa321878&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa321878",
  null
  
)

export default component.exports